@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* This file originally contained *all* CSS for this app.  Over time
   some has been split out.  What remains is either (1) global styles
   or (2) not-yet-organized styles or (3) leftover / unused / broken junk.
   TODO(2): continue cleaning out this file and organizing everything. */

/* Page Layout and Styling */

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
}

/* Everything between header and footer */
.main-area {
  /* Fill the remaining space */
  flex: 1 0 auto;
  margin: 1em;
  align-items: center;
}

@media (max-width: 768px) {
  .main-area {
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
}

/* The container for content in the main area */
/* TODO(2): rename these as column flex container? */
.flex-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
}

@media (max-width: 768px) {
  .flex-container {
    max-width: 100%;
  }
}

/* used for e.g. admin / expert pages */
.flex-container.full-width {
  max-width: 90%;
}

/* TODO(2): this doesn't seem to actually be used for items in
  the main area, ie not related to the flex-container above...  rename */
.flex-item {
  margin: 0.4em;
  padding: 0.2em;
  width: 100%;
}

.flex-row-item {
  margin: 0.6em;
}

/* For individual content blocks on the page.
   should be called... content-block?  content-card?
   Also this is now used for other things like the login form...
   */
.content {
  padding: 0.2em 0.5em;
  margin-bottom: 1.5rem;
  border-radius: 1rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;
  background-color: rgb(var(--content-bg-rgb));
}

/* Clear float after concept links with images */
.content p::after {
  content: '';
  display: table;
  clear: both;
}

/* Content card layout */

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* Used for lists of small items (e.g., users, communities */

.flex-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Text styling */

body {
  font-family: 'Open Sans', 'Arial', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  margin-top: 0.5em;
}

h1,
h2 {
  margin-bottom: 0.8em;
}

h3,
h4,
h5,
h6 {
  margin-bottom: 0.25em;
}

.inline-emphasis {
  font-weight: bold;
}

.logo-font {
  font-family: 'Dosis', sans-serif;
  font-weight: 500;
}

.content-title {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.content a {
  color: rgb(var(--fg-link-rgb));
}
.content a:visited {
  color: rgb(var(--fg-link-visited-rgb));
}
.content a:hover {
  color: rgb(var(--fg-link-hover-rgb));
}

code {
  /* for now, force wordwrap on code.  scrollbar styles below don't seem to work */
  white-space: pre-wrap; /* Allows line wrapping */
  word-break: break-word; /* Breaks long words if necessary */

  /* not working: */
  /* white-space: pre;         keeps text on a single line */
  /* overflow-x: auto;         adds horizontal scrollbar for overflow */
  /* display: inline-block;    ensures scrollbar works correctly */
  /* max-width: 100%;          Prevents expanding beyond the container */
}

/* Toggle Container */
/* Not sure how essential these styles are */

.toggle-container {
  display: inline-block;
  width: 100%;
}

.toggle-container-content {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  background-color: rgb(var(--content-bg-rgb));
  border-radius: 5px;
  margin-top: 10px;
}

.toggle-container-content h2 {
  margin-top: 0;
}

.collapsible__content {
  flex-grow: 1;
  margin-right: 1rem;
}

.toggle-wrapper {
  flex-shrink: 0;
  align-self: flex-end;
}

.collapsible__toggle {
  padding: 0.3rem;
  min-width: 3rem;
  font-size: 1rem;
}

/* Unsorted */

button {
  display: flex;
  gap: 0.8rem;

  font-size: 1rem;
  background: rgb(var(--button-bg-rgb));
  border: none;
  border-radius: 0.4rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: rgb(var(--foreground-rgb));
  cursor: pointer;
  border-radius: 0.4rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgb(var(--button-bg-rgb), 0.7);
}

button:hover {
  background-color: rgb(var(--button-hover-bg-rgb));
}

textarea {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--textbox-bg-rgb));
  border-radius: 0.5rem;
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  margin-bottom: 0;
}

.compose-textarea {
  margin-bottom: 0;
}

.compose-help {
  font-size: 0.85em;
  font-style: italic;
  margin: 0;
}

input {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--textbox-bg-rgb));
  border: none;
  border-radius: 0.2rem;
  padding: 0.4rem;
  width: 100%;
  box-sizing: border-box;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin-top: 2rem;
  margin-left: 2rem;
}

.form-group {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-group label {
  flex: 0 0 140px;
  margin-right: 1rem;
}

.form-group input {
  flex: 1 1;
  font-size: 16px;
}

.submit-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

/* class for divs that should be right-aligned */
.right-align {
  display: flex;
  justify-content: flex-end;
}

.link-item {
  margin-left: 0.6em;
}

.no-decoration {
  text-decoration: none;
}

.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: rgb(var(--content-bg-rgb));
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Actually more generally used for button rows on forms */
.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.selected-topic-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.remove-topic-button {
  background: none;
  border: none;
  color: rgb(var(--foreground-rgb));
  cursor: pointer;
  font-size: 1rem;
  padding: 0 5px;
}

.remove-topic-button:hover {
  color: rgb(255, 0, 0);
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ef9a9a;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  font-weight: bold;
}

.backend-offline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgb(var(--content-bg-rgb));
  color: rgb(var(--foreground-rgb));
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
}

.feed-item {
  margin-bottom: 1rem;
}

.feed-item:last-child {
  margin-bottom: 0;
}

/* Styles for the suggested follows list */
.suggested-follows-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.suggested-follows-item {
  display: contents;
}

.suggested-follows-item > * {
  padding: 5px;
}

.suggested-follows-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.user-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.user-list-item {
  display: contents;
}

.user-list-item > * {
  padding: 5px;
}

.title-buttons-row {
  display: flex;
  justify-content: space-between;
}

.join-button {
  height: 2.8em;
}

.user-suggestions {
  position: absolute;
  background-color: rgb(var(--textbox-bg-rgb));
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
  font-family: inherit;
  font-size: inherit;
  margin-top: 25px; /* Add some margin to separate from the cursor */
}

.user-suggestion {
  padding: 5px 10px;
  cursor: pointer;
  color: rgb(var(--foreground-rgb));
}

.user-suggestion:hover,
.user-suggestion.selected {
  background-color: rgba(var(--foreground-rgb), 0.1);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.inline-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline;
}

.inline-list li {
  display: inline;
}

.inline-list li:not(:last-child)::after {
  content: ',';
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0 0.5rem;
  cursor: pointer;
  color: inherit;
  display: inline-flex;
  align-items: center;
}

.icon-button:hover {
  opacity: 0.7;
}

.visibility-option.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.visibility-disabled {
  opacity: 0.5;
}

.no-decoration {
  text-decoration: none;
  color: inherit;
}

.no-decoration:hover {
  opacity: 0.7;
}

.compose-controls {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 1.5rem;
}

.registration-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 600px;
  margin: 2rem auto;
}

.registration-form-line {
  display: grid;
  grid-template-columns: minmax(150px, auto) 1fr;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
}

.registration-form-line label {
  text-align: right;
  white-space: nowrap;
  padding-right: 0.5rem;
}

.registration-form-line input {
  width: 100%;
  padding: 0.5rem;
}

.registration-form-line button {
  grid-column: 2;
  justify-self: start;
}

.password-strength-bar {
  width: 100%;
  height: 4px;
  background-color: transparent;
  border-radius: 2px;
  margin-top: 4px;
}

.password-strength-fill {
  height: 100%;
  border-radius: 2px;
  transition:
    width 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

.strength-weak {
  background-color: #ff4444;
}

.strength-fair {
  background-color: #ffa700;
}

.strength-good {
  background-color: #00c851;
}

.strength-strong {
  background-color: #007e33;
}

